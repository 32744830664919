<template>
  <section :class="['back-block', rootClass]">
    <span class="back-block__text">Пополнить баланс через прежний вид платёжной страницы — Pop-up</span>
    <div class="back-block__button">
      <SharedKitButton
        :color-preset="MainPageButtonPreset.TAB"
        :type-preset="TypePresets.RECTANGLE"
        :size-preset="changeMD(SizePresets.S, SizePresets.M)"
        :text="changeMD('Открыть', '')"
        :link-props="{
          to: useLinks().BALANCE_POPUP,
          external: true,
        }"
      >
        <template #default>
          <SvgoPaymentBack v-if="changeMD(false, true)" class="back-block__button-icon" />
        </template>
      </SharedKitButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { TypePresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { MainPageButtonPreset } from '~/features/mainPage/presets/SharedKitButton.presets';

const changeMD = GlobalUtils.Media.changeByMedia('md');

const route = useRoute();

const rootClass = computed(() => ({
  'back-block--show': useRouteBaseName()(route) !== ROUTING.PAYMENT.RESULT.name,
}));
</script>

<style scoped lang="scss" src="./BackBlockController.scss"></style>
